import React, { useState, useEffect } from 'react';
import { PageHeaderComponent } from '../../components/page-header/page-header.component';
import { getData } from '../../api/AxisConfig';
import { getURL } from '../../api/URL';

export const DashBoardPage = () => {
    const [visitorCount, setVisitorCount] = useState(0);
  

    const fetchYears = () => {
       
        getData(getURL('GET_COUNT', 'VVES_VISITOR_COUNT'), (resp) => {
            if (resp.status === 200 && resp.statusText === 'OK') {
                setVisitorCount(resp.data.data.visitor_count);
            }
        }, (err) => {

        })
    }

    // Simulating data fetch
    useEffect(() => {
      setTimeout(() => {
        fetchYears();
        setVisitorCount(523); // Example count
      }, 1000);
    }, []);
  

    const DashboardTile = ({ title, count }) => {
        return (
          <div className="bg-blue-100 p-6 rounded-lg shadow-md text-center w-40 m-4">
            <h3 className="text-lg font-medium text-gray-700 ">{title}</h3>
            <p className="text-4xl font-bold text-blue-600">{count}</p>
          </div>
        );
      };


    return (
      <div>
        <PageHeaderComponent title="Dashboard" />
        <div className="flex flex-wrap justify-center">
          <DashboardTile title="Visitor Count" count={visitorCount} />
        </div>
      </div>
    );
  };

